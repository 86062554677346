* {
    color: red;
    background-color: black;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    h1,
    h2,
    p {
        text-align: center;
    }
}
